import React from "react";

import {
  Bleed,
  Box,
  Columns,
  PageBlock,
} from "@envato/design-system/components";
import {
  useClient,
  useConfig,
  useLocalisedPath,
  useTranslation,
} from "@envato/sso-common";

import clientLogo from "../../utils/clientLogo";
import { Logo } from "../Logo";
import { AccountMenu } from "./AccountMenu";
import { HeaderButtonLink } from "./AccountMenu/HeaderButtonLink";
import { NavigationDrawer } from "./NavigationDrawer";
import { HideAbove1300, HideBelow1300 } from "./styles";

export type HeaderProps = {
  variant?: "signIn" | "signUp";
};

const Header: React.FC<HeaderProps> = ({ variant = "signIn" }) => {
  const client = useClient();
  const [{ currentUser }] = useConfig();
  const homeLink = useLocalisedPath("home");
  const t = useTranslation();

  const logo = clientLogo(client?.appId);

  return (
    <PageBlock
      maxWidth="breakpoint-2k-wide"
      colorScheme="dark"
      backgroundColor="primary"
      containerType="inline-size"
    >
      <Box display="grid" position="sticky" top="sticky" zIndex="1">
        <Columns justifyContent="space-between" spacing="3x">
          <Box display="flex" height="full" alignItems="center">
            <Logo linkTo={homeLink} logoSrc={logo} />
          </Box>
          <Box paddingY="1x">
            <HideAbove1300>
              {currentUser?.username && (
                <Bleed right="3x">
                  <AccountMenu currentUser={currentUser} />
                </Bleed>
              )}
            </HideAbove1300>
            <HideBelow1300>
              {currentUser?.username && (
                <NavigationDrawer
                  currentUser={currentUser}
                  homeLink={homeLink}
                  logo={logo}
                />
              )}
            </HideBelow1300>
            {!currentUser?.username && (
              <Bleed right="2x">
                <HeaderButtonLink
                  link={useLocalisedPath(variant)}
                  label={t(`topbar.${variant}`)}
                />
              </Bleed>
            )}
          </Box>
        </Columns>
      </Box>
    </PageBlock>
  );
};

export default Header;
